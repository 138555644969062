<template>
  <ion-page>

    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title slot="start"><ion-img src="/assets/logo.png" class="logo" /></ion-title>

        <ion-select justify="end" :value="channel" slot="end"  :class="{'select_channel': true, 'mob': channel == 1, 'nb': channel == 2,  'app': channel == 3 }" @ionChange="handleSelect($event)">
          <ion-select-option value="1">{{$t('mobileText')}}</ion-select-option>
          <ion-select-option value="2">{{$t('notebookText')}}</ion-select-option>
          <ion-select-option value="3">{{$t('appliancesText')}}</ion-select-option>
        </ion-select>

      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-tabs>

        <template v-if="channel == '1'">
          <ion-tab-bar slot="top" class="mob">
            <ion-tab-button tab="mobile-checkup" @click="handTabMove('1')" :class="[channel1Tab1.indexOf(route.name) > -1 ? 'tab-selected' : '']">
            <ion-label>{{$t('labelTabText1')}}</ion-label>
            </ion-tab-button>

              <ion-tab-button tab="mobile-sale-application" @click="handTabMove('2')" :class="[channel1Tab2.indexOf(route.name) > -1 ? 'tab-selected' : '']">   
                <ion-label>{{$t('labelTabText2')}}</ion-label>
              </ion-tab-button>

            <ion-tab-button tab="mobile-performance" @click="handTabMove('3')" :class="[channel1Tab3.indexOf(route.name) > -1 ? 'tab-selected' : '']">          
              <ion-label>{{$t('labelTabText3')}}</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </template>

        <template v-if="channel == '2'">
          <ion-tab-bar slot="top" class="nb">
            <ion-tab-button tab="check" @click="handTabMove('1')" :class="[channel2Tab1.indexOf(route.name) > -1 || route.params.isState == 'check' ? 'tab-selected' : '']">
            <ion-label>{{$t('labelTabText1')}}</ion-label>
            </ion-tab-button>

              <ion-tab-button tab="apply" @click="handTabMove('2')" :class="[channel2Tab2.indexOf(route.name) > -1 || route.params.isState == 'apply' ? 'tab-selected' : '']">   
                <ion-label>{{$t('labelTabText2')}}</ion-label>
              </ion-tab-button>

            <ion-tab-button tab="result" @click="handTabMove('3')" :class="[channel2Tab3.indexOf(route.name) > -1 || route.params.isState == 'result' ? 'tab-selected' : '']">          
              <ion-label>{{$t('labelTabText3')}}</ion-label>
            </ion-tab-button>

          </ion-tab-bar>          
        </template>

        <template v-if="channel == '3'">
          <ion-tab-bar slot="top" class="app">
            <ion-tab-button tab="check" @click="handTabMove('1')" :class="[channel3Tab1.indexOf(route.name) > -1 || route.params.isState == 'check' ? 'tab-selected' : '']">
            <ion-label>{{$t('labelTabText1')}}</ion-label>
            </ion-tab-button>

              <ion-tab-button tab="apply" @click="handTabMove('2')" :class="[channel3Tab2.indexOf(route.name) > -1 || route.params.isState == 'apply' ? 'tab-selected' : '']">   
                <ion-label>{{$t('labelTabText2')}}</ion-label>
              </ion-tab-button>

            <ion-tab-button tab="result" @click="handTabMove('3')" :class="[channel3Tab3.indexOf(route.name) > -1 || route.params.isState == 'result' ? 'tab-selected' : '']">          
              <ion-label>{{$t('labelTabText3')}}</ion-label>
            </ion-tab-button>

          </ion-tab-bar>            
        </template>


        <ion-router-outlet id="main"></ion-router-outlet>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonHeader, 
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  loadingController,
  IonPage, 
  IonTabs, 
  IonTabBar, 
  IonTabButton, 
  IonLabel,
  IonContent,
  useIonRouter,
  IonSelect, 
  IonSelectOption
} from '@ionic/vue'
import { useRoute } from 'vue-router'
import { defineComponent, ref, computed, inject, onMounted, watch, nextTick } from 'vue'
import { ellipse, square, personOutline, clipboardOutline } from 'ionicons/icons'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Default',
  components: {
    IonHeader, 
    IonToolbar,
    IonTitle,
    IonRouterOutlet,
    IonPage, 
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonLabel,
    IonContent,
    IonSelect, 
    IonSelectOption
  },
  setup() {

    const route = useRoute()
    const router = useIonRouter()
    const eventBus = inject('$eventBus')
    const store = useStore()
    const token = computed(() => store.state.User.token)
    const channel = ref(route.meta.channel)
    let loading = ref(undefined)
    let isOpenRef = ref(false)

    watch(
      () => route.meta.channel,
      async () => {
        await nextTick(() => {        
          channel.value = route.meta.channel

          const el = document.body;
          el.classList.remove('mob');
          el.classList.remove('nb');
          el.classList.remove('app');

          let className = 'mob'
          if(channel.value == 1){
            className = 'mob'
          }else if(channel.value == 2){
            className = 'nb'
          }else if(channel.value == 3){
            className = 'app'
          }

          el.classList.add(className);
        })
      },
      {
        immediate: true
      }
    )

    onMounted(() => {
      eventBus.on('loading', (power, loadingText) => {
        handleLoading(power, loadingText)
      })
      channel.value = route.meta.channel 
    })

    const channel1Tab1 = ['mobile-checkup','mobile-survey']
    const channel1Tab2 = ['mobile-sale-application','mobile-survey-file']
    const channel1Tab3 = ['mobile-performance']

    const channel2Tab1 = ['qr-scanner','inspection-info','survey','photos']
    const channel2Tab2 = []
    const channel2Tab3 = []

    const channel3Tab1 = ['appliances-survey','appliances-survey-file']
    const channel3Tab2 = []
    const channel3Tab3 = []


    const handleLoading = async (power, loadingText = '로딩중...') => {
      if (power) {
        loading.value = await loadingController.create({
          cssClass: 'my-custom-class',
          message: loadingText,
        })
        await loading.value.present()
      } else {
        await loading.value.dismiss()
      }
    }

    const setOpen = (state) => (isOpenRef.value = state)

    const setClose = (state) => {
      isOpenRef.value = state
    }

    const handleLogin = () => {
      setOpen(true)
    }

    const pageMove = ( name, params ) => {
      router.push({
        name: name,          
        params: params
      })  
    }

    const handTabMove = (val) => {

      if(channel.value == '1'){

        switch(val) {
          case '1':  
            pageMove('mobile-checkup', {})
            break
          case '2':  
            pageMove('mobile-sale-application', {})
            break
          case '3':  
            pageMove('mobile-performance', {})
            break
          default:
            pageMove('mobile-checkup', {})
            break
        }

      }else if(channel.value == '2'){
        
        switch(val) {
          case '1':  
            pageMove('checkup', {isState : 'check'})
            break
          case '2':  
            pageMove('checkup', {isState : 'apply'})
            break
          case '3':  
            pageMove('checkup', {isState : 'result'})
            break
          default:
            pageMove('checkup',  {isState : 'check'})
            break
        }

      }else if(channel.value == '3'){

        switch(val) {
          case '1':  
            pageMove('appliances-checkup', {isState : 'check'})
            break
          case '2':  
            pageMove('appliances-checkup', {isState : 'apply'})
            break
          case '3':  
            pageMove('appliances-checkup', {isState : 'result'})
            break
          default:
            pageMove('appliances-checkup',  {isState : 'check'})
            break
        }

      }

    }

    const handleSelect = (e) => {

      channel.value = e.detail.value
      
      switch(e.detail.value) {
        case '1':  
          pageMove('mobile-checkup', {})
          break
        case '2':  
          pageMove('checkup', {isState : 'check'})
          break
        case '3':  
          pageMove('appliances-checkup', {isState : 'check'})
          break
        default:
          pageMove('mobile-checkup', {})
          break
      }

    }

    const atest = () =>{

    }
    return {
      channel1Tab1,
      channel1Tab2,
      channel1Tab3,
      channel2Tab1,
      channel2Tab2,
      channel2Tab3,
      channel3Tab1,
      channel3Tab2,
      channel3Tab3,      
      ellipse,
      square,
      personOutline,
      clipboardOutline,
      loading,
      isOpenRef,
      handleLoading,
      setOpen,
      setClose,
      handleLogin,
      handTabMove,
      handleSelect,
      atest,
      channel,
      route,
      // store state
      token
    }
  }
})
</script>

<style scoped>
.select_channel {
    width: 50%;
    justify-content: center;
    margin-right: 5%;
    --border-style: double ;
    --border-width: 1px 0;
    --border-color: #f24aec;
  }

.title-default :deep ion-img {
  width: 100px;
  margin: 0 auto;
}

.ion-activatable :deep ion-avatar {
  width: 32px;
  height: 32px;
}
ion-header {
  display: flex;
}
ion-header ion-title {
  width: auto;
  margin-left: 20px;
  padding: 0;
}
:deep ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}
.mob ion-tab-button {
  --color-selected: var(--ion-color-tertiary-shade);
}
.mob ion-tab-button.tab-selected {
  color: var(--ion-color-tertiary-shade) !important;
}
.nb ion-tab-button {
  --color-selected: var(--ion-color-primary) !important;
}
.nb ion-tab-button.tab-selected {
  color: var(--ion-color-primary) !important;
}
.app ion-tab-button {
  --color-selected: var(--ion-color-success) !important;
}
.app ion-tab-button.tab-selected {
  color: var(--ion-color-success) !important;
}

ion-tab-bar > ion-tab-button > ion-label {
  font-size:  14px;
}
</style>
